import analytics from 'modules/Analytics';
import FamilyPlanChildSection from './FamilyPlanChildSection';
import FamilyPlanChildValidationSection from './FamilyPlanChildValidationSection';
import FamilyPlanNonEligableSection from './FamilyPlanNonEligableSection';
import FilledButton from 'primitives/Buttons/FilledButton';
import Footer from 'components/Footer';
import H1 from 'primitives/Typography/Headings/H1';
import logger, { CONTEXTS } from 'modules/Logger';
import MessagingBlock from './primitives/MessagingBlock';
import P from 'primitives/Typography/BodyCopy/P';
import ValidationContainer from './primitives/ValidationContainer';
import { acceptInvitation } from 'shared/api/subscription';
import { Component } from 'react';
import { IGetTranslateFunctionResponse } from 'redux-i18n';

type Props = {
  ampUrl: string;
  isAnonymous: boolean;
  isFamilyPlanChild: boolean;
  openSignup: () => void;
  openFailureModal: () => void;
  openSuccessModal: () => void;
  profileId: number;
  queryParam: {
    token: string;
    zipcode: string;
  };
  sessionId: string;
  source: 'ROKU' | 'IOS' | 'AMAZON' | 'RECURLY' | 'GOOGLE';
  translate: IGetTranslateFunctionResponse;
};

class FamilyPlanValidation extends Component<Props> {
  componentDidMount() {
    const { isAnonymous, openSignup } = this.props;

    if (isAnonymous) {
      openSignup();
    }
  }

  handleSignupClick = () => {
    const { openSignup } = this.props;

    openSignup();
  };

  submitForm = (zipcode: HTMLInputElement | null | undefined) => {
    if (!zipcode || !zipcode.value) return;

    const {
      ampUrl,
      openFailureModal,
      openSuccessModal,
      profileId,
      queryParam,
      sessionId,
    } = this.props;

    const { token } = queryParam;

    acceptInvitation({
      ampUrl,
      profileId,
      sessionId,
      token,
      zipcode: zipcode.value,
    })
      .then(() => {
        analytics.trackPageView!({
          filterName: 'success',
          filterType: 'zipcode_validation',
          pageName: 'validation_prompt_success',
        });
        openSuccessModal();
      })
      .catch((error: any) => {
        const errObj =
          error instanceof Error
            ? error
            : new Error(error.statusText ?? 'family plan validation error');
        logger.error(
          [CONTEXTS.SUBSCRIPTION, CONTEXTS.FAMILY_PLAN, CONTEXTS.FORM],
          errObj.message,
          {},
          errObj,
        );
        analytics.trackPageView!({
          filterName: 'failure',
          filterType: 'zipcode_validation',
          pageName: 'validation_prompt_failure',
        });
        openFailureModal();
      });
  };

  render() {
    const { isFamilyPlanChild, translate, isAnonymous, source } = this.props;

    let userView;

    if (isAnonymous) {
      userView = (
        <MessagingBlock>
          <H1>{translate('Please create a free account, or sign in')}</H1>
          <P>
            {translate(
              'To accept the Family Plan invitation, you must be logged in.',
            )}
          </P>
          <FilledButton
            aria-label={translate('Sign Up')}
            center
            data-test="sign-up-for-free-account-family-plan"
            isBlock
            marginBottom
            marginTop="1.5rem"
            onClick={this.handleSignupClick}
            styleType="cta"
            type="submit"
          >
            {translate('Sign Up')}
          </FilledButton>
        </MessagingBlock>
      );
    } else if (isFamilyPlanChild) {
      userView = <FamilyPlanChildSection translate={translate} />;
    } else if (source === 'RECURLY') {
      userView = (
        <FamilyPlanChildValidationSection
          submitForm={this.submitForm}
          translate={translate}
        />
      );
    } else {
      userView = <FamilyPlanNonEligableSection translate={translate} />;
    }

    return (
      <>
        <ValidationContainer>{userView}</ValidationContainer>
        <Footer />
      </>
    );
  }
}

export default FamilyPlanValidation;
